import Script from "next/script";
import { useEffect, useState } from "react";
import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import type { TrackingDataModel } from "@/hooks/tracking/models/tracking-data-model.types";
type InitLinkedInProps = {
  readonly trackingData: TrackingDataModel;
};
export function InitializeLinkedInInsightTag({
  trackingData
}: InitLinkedInProps) {
  const consent = useCookiebotConsent();
  const [isMarketingConsentGiven, setIsMarketingConsentGiven] = useState(false);
  useEffect(() => {
    setIsMarketingConsentGiven(consent.marketing);
    /* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,functional/immutable-data -- naming decided by script */
    const _linkedin_partner_id = trackingData.linkedinInsightId;
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids ?? [];
    if (!window._linkedin_data_partner_ids.includes(_linkedin_partner_id)) {
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    }
    /* eslint-enable @typescript-eslint/naming-convention,no-underscore-dangle,functional/immutable-data */
  }, [trackingData.linkedinInsightId, consent]);
  return isMarketingConsentGiven ? <Script id="LinkedInInsight" src="https://snap.licdn.com/li.lms-analytics/insight.min.js" strategy="afterInteractive" data-sentry-element="Script" data-sentry-component="InitializeLinkedInInsightTag" data-sentry-source-file="InitializeLinkedInInsightTag.tsx" /> : null;
}